<template>
  <div id="app" v-loading="loading">
    <Home />
  </div>
</template>

<script>
import Home from "./views/Home";
import { mapMutations, mapState, mapActions } from "vuex";
import Pusher from "pusher-js";
import { getTimestamp, getParameterByName } from "./utils";

export default {
  name: "app",
  components: {
    Home,
  },
  data() {
    return {
      timer: null,
      pusher: null,
      orderId: null,
    };
  },
  metaInfo() {
    return {
      title: this.appBrand?.text || "MugglePay", // Dynamic title from component data
      link: [
        {
          rel: "icon",
          href:
            this.appBrand?.url ||
            "https://mugglespublicweb.s3.ap-southeast-1.amazonaws.com/imgs/mugglepay_logo.jpg",
        },
      ],
    };
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    ...mapState({
      appBrand: (state) => state.appBrand,
      orderData: (state) => state.orderData,
      payType: (state) => state.payType,
      lastTime: (state) => state.orderData.invoice.expired_at_t,
      loading: (state) => state.loading,
      pageStatus: (state) => {
        let invoice = state.orderData.invoice;
        if (!invoice) {
          return "";
        } else {
          // return 'NEW'
          if (invoice.status == "PENDING") {
            // PENDING UNDERPAID WRONGPAID
            return (invoice.crypto_info && invoice.crypto_info.paidStatus) || invoice.status;
          } else {
            // NEW PAID EXPIRED
            return invoice.status;
          }
        }
      },
    }),
  },
  methods: {
    ...mapMutations([
      "setOrder",
      "addLoading",
      "removeLoading",
      "handleDetailPageStatus",
      "setRemainTime",
      "setExpired",
      "setListStatus",
      "setAppBrand",
    ]),
    ...mapActions(["getAppBrand", "getOrderData"]),
    async handleSystemData() {
      this.addLoading();
      try {
        const host = window.location.hostname.replace(/^www\./, "");
        const domain = host === "localhost" ? "mugglepay.com" : host;

        const res = await this.getAppBrand(domain || "mugglepay.com");
        if (res?.data.status === "success") {
          const brand = res?.data?.brand;
          this.setAppBrand(brand);
        }

        this.getData();
        this.addPoll();
      } catch (error) {
        this.$message({
          message: error.message,
          type: "error",
        });
      }
    },
    getData() {
      // let order = getParameterByName('id');
      this.getOrderData(this.orderId).then((res) => {
        if (res.data) {
          this.removeLoading();
          this.setOrder(res.data);
          this.handleRemainingTime(res.data.invoice && res.data.invoice.expired_at_t);
          this.handlePermission(res.data);

          // Disconnect the pusher connection after the order already PAID or EXPIRED
          if (res.data?.order?.status === "PAID" || res.data?.order?.status === "EXPIRED") {
            clearInterval(this.$store.state.dataTimer);
            this.pusher.disconnect();
          }
        }
      });
    },
    addPoll() {
      let timeForRepeat = 5000;
      this.$store.state.dataTimer = setInterval(() => {
        this.getData();
      }, timeForRepeat);
    },
    // 订单失效时间计算
    handleRemainingTime(lastTime) {
      this.calcTime(lastTime);
      let repeatTime = 1000;
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setInterval(() => {
        this.calcTime(lastTime);
      }, repeatTime);
    },
    calcTime(lastTime) {
      let targetTime = new Date().getTime();
      // 时间检测为过期，但是流程没有走完则显示过期(pending状态待确认)
      if (targetTime >= lastTime && this.pageStatus != "PAID" && this.pageStatus != "PENDING") {
        this.setExpired();
      } else {
        this.setRemainTime(getTimestamp(lastTime));
      }
    },
    handlePermission(data) {
      this.setListStatus(data.merchant);
    },
  },
  watch: {
    payType(val) {
      // 触发checkout接口
      if (val) {
        this.handleDetailPageStatus(1);
        this.handleRemainingTime(this.lastTime);
      } else {
        // this.handleDetailPageStatus(0)
      }
    },
  },
  created() {
    this.orderId = getParameterByName("id");

    if (this.orderId) {
      const pusher = new Pusher(this.$env.VUE_APP_PUSHER_KEY, {
        cluster: this.$env.VUE_APP_PUSHER_CLUSTER,
      });
      this.pusher = pusher.subscribe(`invoice-${this.orderId}`);
      this.pusher.bind("update-status", (payload) => {
        // this.getData();
        if (this.orderId === payload?.id && payload.hasOwnProperty("status")) {
          // Get Old Data then replace the status
          const data = this.orderData;
          const invoice = Object.assign({}, data.invoice, { status: payload?.status });

          this.setOrder({ ...data, invoice });
        }
      });
    }
  },
  mounted() {
    // 系统数据入口
    this.handleSystemData();
  },
  beforeDestroy() {
    clearInterval(this.$store.state.dataTimer);
    this.pusher.unsubscribe(`invoice-${this.orderId}`);
  },
};
</script>

<style lang="scss">
body,
html {
  margin: 0px;
  padding: 0px;
}

* {
  box-sizing: border-box;
  font-family: Arial, sans-serif, "华文细黑";
}

#app {
  font-family: Arial, sans-serif, "华文细黑";
  width: 100vw;
  min-height: 100vh;
  font-size: 14px;
}

.el-loading-spinner .path {
  stroke: #55b5ff !important;
}
</style>
